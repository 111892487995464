.heading{
    font-size: 40px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}
.maincon{
    margin: 115px 50px;
    width: 90%;
}
.para{
    font-family: "Work Sans", sans-serif;
   font-size: 13px;
}
.para-head{
    font-size: 14px;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
}
.para-con{
    font-size: 13px;
    font-family: "Work Sans", sans-serif;
    font-weight: 500;
}
@media (max-width: 699px) {
.main{
    margin: 85px auto;
}
}
