.yeteh-main {
  font-family: "Amatic SC", cursive;
  margin-top: 80px;
  color: #ffffff;
  box-sizing: border-box;
  h1,
  h2,
  h4,
  h5,
  p {
    margin: 0;
  }

  .main-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(80vh - 80px);
    background-size: cover;
    background-image: url("../assets/landing-page/home-bg.png");
    padding-left: 3.5%;
    h1 {
      font-size: 5rem;
      line-height: 0.8;
      margin: 1rem 0;
      span {
        line-height: 1.8;
        font-weight: 400;
        font-size: 3rem;
      }
    }

    h5 {
      font-family: "Work Sans", sans-serif;
      font-size: 1rem;
      margin: 1rem 0;
    }

    button {
      margin: 1rem 0;
      align-self: flex-start;
      font-weight: 700;
    }
  }

  // #2E2E2E

  .planning-banner {
    min-height: calc(20vh);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.09),
        rgba(255, 255, 255, 0.09)
      ),
      #121212;

    h2 {
      color: #f0682f;
      font-size: 4rem;
    }

    h5 {
      font-family: "Work Sans", sans-serif;
      font-size: 1rem;
    }
  }

  .info-section {
    background: #2e2e2e;

    article {
      display: flex;
      padding: 3.5%;

      h2 {
        color: #f0682f;
        font-size: 4rem;
      }

      p {
        font-family: "Work Sans", sans-serif;
        font-size: 1.5rem;
      }

      > div {
        max-width: 50%;
        flex-basis: 50%;
        flex-shrink: 0;
      }

      svg {
        padding: 0 3%;
        height: 100%;
        width: 100%;
      }
    }

    article:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .join-banner {
    padding: 3.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("../assets/landing-page/home-bg-2.png");
    background-size: cover;
    min-height: 60vh;
    h2 {
      font-size: 5rem;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    background: #28292b;

    h4 {
      font-family: "Work Sans", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      word-spacing: 0.2rem;

      svg {
        margin: 0 5px;
        height: 16px;
        width: 16px;
      }

      a {
        padding: 5px;
        color: white;
      }
    }
  }
}
