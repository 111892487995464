html {
  font-size: 16px;
}

@media (max-width: 990px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 10px;
  }
}

.date-picker {
  .MuiPickersToolbar-toolbar {
    background: #2e2e2e;
    h6,
    h4 {
      color: white;
    }
  }

  .MuiPickersDay-day {
    p {
      color: white;
    }
  }

  .MuiPickersCalendarHeader-daysHeader {
    span {
      color: white;
    }
  }

  .MuiPickersDay-daySelected {
    background: #f26724;
    p {
      color: white;
    }
  }
}

.leaflet-container {
  height: 100%;
  width: 100%;
  position: relative;
  opacity: 1;
}

#yeteh-search .gsc-adBlock {
  display: none;
}
.gsc-control-cse {
  background-color: #2e2e2e !important;
  border: none !important;
  form {
    border: 1px solid rgba(255, 255, 255, 0.37) !important;
    border-radius: 4px;
    .gsc-search-box {
      button.gsc-search-button {
        background-color: transparent !important;
        border: none !important;
      }
      .gsc-input-box {
        background-color: #2e2e2e !important;

        border: none;
        input {
          background: #2e2e2e !important;
          color: white !important;
        }
      }
    }
  }

  .gsc-above-wrapper-area {
    border: none !important;
  }

  .gsc-webResult.gsc-result {
    border: none !important;
    background: black !important;
    padding: 10px 0 5px 20px;

    .gs-result .gs-title,
    .gs-result .gs-title * {
      color: #f4b8e1;
      text-decoration: none;
    }

    .gs-webResult div.gs-visibleUrl-short,
    .gs-webResult div.gs-visibleUrl,
    .gs-imageResult div.gs-visibleUrl,
    .gs-webResult .gs-snippet,
    .gs-imageResult .gs-snippet,
    .gs-fileFormatType {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .gsc-results .gsc-cursor-box,
  .gsc-results .gsc-cursor-box .gsc-cursor-page {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6);
  }

  .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    color: #f26724;
  }
}
